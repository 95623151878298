.background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  /*filter: grayscale(100%) opacity(35%);*/
  mix-blend-mode: multiply;
}


.background1 {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  /*filter: grayscale(100%) opacity(35%);*/
  mix-blend-mode: multiply;
	background-image: url(../../assets/images/main-slider/portada-1.jpeg);
  background-size: cover;
}

.background2 {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  /*filter: grayscale(100%) opacity(35%);*/
  mix-blend-mode: multiply;
	background-image: url(../../assets/images/main-slider/portada-2.jpeg);
  background-size: cover;
}

.background3 {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  /*filter: grayscale(100%) opacity(35%);*/
  mix-blend-mode: multiply;
	background-image: url(../../assets/images/main-slider/portada-3.jpeg);
  background-size: cover;
}