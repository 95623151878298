.section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  position: relative;
  color: #fff !important;
}

.awssld--fill-parent {
  height: 100vh !important;
}
