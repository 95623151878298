
/* Slider Animation */

.slide h1 {
	transition       : all 0.3s ease;
	-webkit-transform: translateY(-20px);
	transform        : translateY(-20px);
	opacity          : 0;
}

.slide h2 {
	transition       : all 0.3s ease;
	-webkit-transform: translateX(-20px);
	transform        : translateX(-20px);
	opacity          : 0;
}

.slide .btn-box {
	transition       : all 0.3s ease;
	-webkit-transform: translateY(20px);
	transform        : translateY(20px);
	opacity          : 0;
}

.slide .text {
	transition       : all 0.3s ease;
	-webkit-transform: translateX(20px);
	transform        : translateX(20px);
	opacity          : 0;
}

.slide section * {
	transition: all 0.3s ease;
}



.slide.animateIn.previous h1,
.slide.current h1,
.slide.current h2,
.slide .btn-box,
.slide .text,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
	-webkit-transform       : translateX(0);
	transform               : translateX(0);
	-webkit-transition-delay: .9s;
	transition-delay        : .9s;
	opacity                 : 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
	-webkit-transition-delay: 1.1s;
	transition-delay        : 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
	-webkit-transition-delay: 1.3s;
	transition-delay        : 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
	-webkit-transition-delay: 1.3s;
	transition-delay        : 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
	-webkit-transition-delay: 1.4s;
	transition-delay        : 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
	-webkit-transition-delay: 1.5s;
	transition-delay        : 1.5s;
}

.slide.animateOut h1 {
	-webkit-transition-delay: .3s;
	transition-delay        : .3s;
}

.slide.animateOut h2 {
	-webkit-transition-delay: .1s;
	transition-delay        : .1s;
}

.slide.animateOut section span {
	-webkit-transition-delay: .1s;
	transition-delay        : .1s;
}

.slide.animateOut section span strong {
	-webkit-transition-delay: 0s;
	transition-delay        : 0s;
}




/* Home Slider */


.slider-wrapper {
	position: relative;
	overflow: hidden;
	height  : 120vh;
}

.slide {
	height         : 120vh;
	background-size: cover !important;
}

.slide::before {
	content   : '';
	display   : block;
	position  : absolute;
	width     : 100%;
	height    : 100%;
	background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
	background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
	bottom    : 0;
	left      : 0;
}

.previousButton,
.nextButton {
	position   : absolute;
	top        : 50%;
	transform  : translateY(-50%);
	z-index    : 10;
	background : url('../images/MM9.svg') no-repeat center center / 16px;
	width      : 64px;
	height     : 64px;
	text-indent: -9999px;
	cursor     : pointer;
}

.previousButton:hover,
.nextButton:hover {
	background: url('../images/MM9.svg') no-repeat center center / 16px;
}

.previousButton {
	left             : 0;
	-webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
	transform        : rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
	left: -10px;
}

.nextButton {
	right: 0;
}

.nextButton:hover {
	right: -10px;
}

.awssld__wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    height: 120vh !important;
    width: 100%;
}

.awssld__content {
    background-color: #516527 !important;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover !important;
}

.banner2-img-wrapper{
	max-width: 30%;
	min-width: 10%;
}


.header-icono{
	max-width: 68px;
	min-width: 30px;
}

.slide {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	min-height: 100%;
	width: 100%;
	background-color: teal;
  }

.slider-content-s{
    background: #282828  !important;
}

.slider-content .inner {
	padding          : 0 70px;
	position         : absolute;
	width            : 100%;
	top              : 40%;
	left             : 50%; 
	text-align		 : center;
	transform        : translate(-50%, -25%);
}

.slider-content .inner button {
	-webkit-transition-delay: 0s;
	transition-delay        : 0s;
}

.slider-content .inner img {
	margin-left: 0%;
}

.slider-content .inner h1 {
	display          : block;
	font-size        : 3.5em;
	line-height      : 1.15em;
	color            : #fff;
	font-weight      : 700;
	margin-top       : 20px;
	margin-bottom    : 20px;
	letter-spacing   : 0; 
	text-transform   : capitalize;
}

.slider-content .inner h3 {
	display          : block; 
	color            : #fff;
	font-weight      : 300; 
	margin-bottom    : 40px; 
	margin-top    : 20px; 
	font-size        : 1.3em;
}

.slider-content .inner h2 {
	display          : block;
	font-size        : 10px;
	line-height      : 1.15em;
	color            : #fff;
	font-weight      : 700;
	letter-spacing   : -10px;
	margin-bottom    : 0;
	position         : relative;
	left             : -10px;
	-webkit-transform: translateY(-100px);
	-ms-transform    : translateY(-100px);
	transform        : translateY(-100px);
	text-transform   : capitalize;
}

.slider-content .inner.inner-2 h2{
	font-size: 120px;
	letter-spacing: -5px;
    line-height: 1em;
	margin-bottom: -85px;

}

.slider-content .inner.inner-2 h2 span{
	font-weight: 700;
	display: block;
	
}
.slider-content .inner h2 span {
	font-weight: 300;
}

.slider-content .inner .text {
	display          : block;
	font-size        : 20px;
	line-height      : 1.6em;
	color            : #c5b552;
	font-weight      : 400;
	margin-bottom    : -80px;
	-webkit-transform: translateY(-120px);
	-ms-transform    : translateY(-120px);
	transform        : translateY(-120px);
	padding-right    : 10px;
}

.slider-content section {
	position: absolute;
	bottom  : 20px;
	left    : 20px;
}

.slider-content section span {
	color: #FFFFFF;
}

.slider-content section span {
	color         : rgba(255, 255, 255, 0.5);
	font-size     : 12px;
	display       : inline-block;
	text-align    : left;
	line-height   : 1.4;
	vertical-align: middle;
	margin-left   : 10px;
}

.slider-content section img {
	width         : 40px;
	height        : 40px;
	border        : solid 2px rgba(255, 255, 255, 0.5);
	border-radius : 100%;
	vertical-align: middle;
}

.slider-content section span strong {
	color    : #FFFFFF;
	font-size: 14px;
	display  : block;
}

 @media (max-width: 640px) {
	.slider-wrapper {
	  height: 70vh;
	}  
	.slider-content {
		height: 70vh; 
	}  
	.slider-content .inner h1{ 
		font-size: 35px;
	}
  }
  /*
  @media (max-width: 640px) {
	.slider-wrapper, .slide {
	  height: calc(80vh - 75px);
	}    
  } */


  .banner-section.style-four .slider-wrapper .slide::before{
	background-image: linear-gradient(90deg, #516527 0%, #516527 100%);
	opacity: .9;
  }
  .banner-section.style-four .slider-wrapper .slide .inner{
	  top: 43%;
  }

  .banner-section.style-four .slider-wrapper .slide .inner h2{
	font-size: 120px;
	margin-bottom: -85px;
	
  }
  .banner-section.style-four .slider-wrapper .slide .inner h2 span{
	  font-weight: 700;
	  display: block;
  }

  
@media (min-width: 900px) {
	.slider-content .inner .text { 
		padding-right    : 30%;;
	} 
	
}

@media (max-width: 765px) {
	.slider-content .inner .text { 
		padding-right    : 30%;;
	}
	.slider-content .inner {
		text-align: center;
	}
	.slider-content .inner img{
		width: 100%;
	}
	 
	.banner2-img-wrapper{
		max-width: 100%;
		min-width: 10%;
	}
}


@media (max-height: 500px) { 
	.slider-content .inner img{
		width: 15%;
	}
	.slider-content .inner h1 {
		font-size: 30px; 
	}
}